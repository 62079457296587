import "./App.css";
import ReactGA from "react-ga4";
import { Navbar } from "./components/Navbar";
import { Firstscreen } from "./components/Firstscreen";
import { Secondscreen } from "./components/Secondscreen";
import { Thirdscreen } from "./components/Thirdscreen";
import { FourthScreen } from "./components/FourthScreen";
import { FifthScreen } from "./components/FifthScreen";
import { Footer } from "./components/Footer";

const TRACKING_ID = "G-MV8FF2TBVS"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">
      <Navbar />
      <header className="min-h-[90vh] bg-[#EEF5FE]">
        <Firstscreen />
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <body>
        <Secondscreen />
        <Thirdscreen />
        <div className="md:h-20 md:w-full bg-[#EEF5FE]"></div>
        <FourthScreen />
        <FifthScreen />
      </body>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
