import React from "react";
import MapComponent from "../assets/mapacompleto.png";

export const Thirdscreen = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center bg-[#EEF5FE]">
      <div className="h-4/5 w-11/12 flex flex-col justify-around md:w-4/6 md:h-full md:pt-32 md:m-w-lg md:pb-20">
        <div className="text-left">
          <h3 className="text-3xl text-primaryBlack font-black font-newFont">
            {" "}
            <span className="text-primaryBlue">Mapa</span> interactivo
          </h3>
          <div className="h-[3px] w-10 bg-primaryBlue mt-5"></div>
          <p className="mt-6 text-xl text-newFont text-primaryBlack">
            Con alertas en vivo y sistema de notificaciones para todas las áreas
            operativas.
          </p>
        </div>
        <div className="h-1/2 md:w-[95%] md:h-[90%] md:mt-12 md:flex md: flex-col md:justify-center md:items-center">
          <img src={MapComponent} className="w-full md:w-4/5" />
          {/* <MapComponent /> */}
        </div>
      </div>
    </div>
  );
};
