import React from "react";
import { BenfitsCard } from "./BenfitsCard";
import mapIcon from "../assets/traillingIcon.png";
import correctIcon from "../assets/correctIcon.png";
import stopIcon from "../assets/stopIcon.png";
import badgeIcon from "../assets/badgeIcon.png";

export const FourthScreen = () => {
  const benefitCard = [
    {
      icon: mapIcon,
      mainText: "Libro de control",
      secondaryText: "Genera proyectos, y comunica incidencias en tiempo real.",
    },
    {
      icon: correctIcon,
      mainText: "Calidad",
      secondaryText: "Asigna estándares de calidad según tus necesidades.",
    },
    {
      icon: stopIcon,
      mainText: "Informes",
      secondaryText:
        "Evalúa puntos críticos de control y recibe información objetiva.",
    },
    {
      icon: badgeIcon,
      mainText: "Firma digital",
      secondaryText:
        "Genera certeza y precisión con la firma electrónica avanzada.",
    },
  ];

  return (
    <div className="w-full bg-primaryBlue md:flex md:justify-center bg-primaryBlue">
      <div className="flex flex-col justify-around items-center md:flex-row md:w-4/5 md:h-full md:my-10 md:justify-between md:m-w-lg">
        {benefitCard.map((card) => (
          <BenfitsCard
            icon={card.icon}
            mainText={card.mainText}
            secondaryText={card.secondaryText}
          />
        ))}
      </div>
    </div>
  );
};
