import React from "react";
import { HeaderImageComponent } from "./HeaderImageComponent";
import { PrimaryButtonComponent } from "./PrimaryButtonComponent";
import { SecondaryButtonComponent } from "./SecondaryButtonComponent";

export const Firstscreen = () => {
  return (
    <div className="flex flex-col justify-center items-center h-2/3 w-full md:h-screen">
      <div className="flex flex-col justify-center items-center w-11/12 mb-14 md:flex-row-reverse md:w-4/5 md:h-5/6 ">
        <div className="flex flex-col justify-center items-center h-full">
          <HeaderImageComponent />
        </div>
        <div className="mt-12 md:h-full md:mt-0 md:flex md:flex-col md:justify-center">
          <div className="md:w-11/12">
            <h1 className="text-2xl md:text-4xl text-left font-newFont md:leading-snug">
              <span className="text-primaryBlue font-black">
                La revolución{" "}
              </span>{" "}
              <span className="text-primaryBlack font-black">
                en el control de operaciones{" "}
              </span>
            </h1>
            <p className="text-left text-primaryBlack text-lg mt-4 font-newFont">
              Controla y supervisa las operaciones de tus empresas contratistas
              fácilmente con una sola herramienta. Conoce el software
              revolucionario que te ayudará a mejorar el desempeño de tu
              organización, ya sea pública o privada.
            </p>
          </div>
          <div className="h-36 flex flex-col justify-around items-center mt-4 md:flex-row-reverse md:w-3/4 md:min-w-[400px] md:justify-between ">
            <SecondaryButtonComponent />
            <PrimaryButtonComponent />
          </div>
        </div>
      </div>
    </div>
  );
};
