import React from "react";
import { InfoTextCard } from "./InfoTextCard";

export const Secondscreen = () => {
  const textObjects = [
    {
      blueText: "Qué",
      whiteText: "hacemos",
      subText: "Transparencia y eficiencia",
      mainText: [
        "Hoy más que nunca, las entidades públicas y privadas necesitan ser transparentes, eficientes y proactivas. Nosotros facilitamos la transformación digital y aportamos la herramienta adecuada a los requerimientos impuestos por ley.",
        <br />,
        <br />,
        "Ayudamos a empresas y gobiernos locales a mejorar sus procesos y tomar decisiones a través del manejo de datos e información objetiva.",
      ],
    },
    {
      blueText: "Cómo",
      whiteText: "lo hacemos",
      subText: "Un ecosistema para la ciudad",
      mainText: [
        "Auditech es una plataforma integrada que cuenta con herramientas de gestión de proyectos públicos.",
        <br />,
        <br />,
        "Mediante el sistema de libros de control digitales, Auditech permite una comunicación instantanea acorde con las nuevas tecnologías. También puede crear y monitorizar puntos críticos de cualquier servicio en un sólo lugar. Permitiendo controlar eventos, registrar incidencias, y comunicar instrucciones de forma eficiente.",
      ],
    },
  ];

  return (
    <div className="flex flex-col justify-center items-center md:w-full">
      <div className="flex flex-col md:items-start items-center mt-10 mb-12 md:w-4/5 md:mt-0 md:mb-0 md:max-w-6xl pb-8">
        {textObjects.map((x, index) => (
          <InfoTextCard
            blueText={x.blueText}
            whiteText={x.whiteText}
            subText={x.subText}
            mainText={x.mainText}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};
