import React from "react";
import logo from "../assets/logoa.png";

export const Footer = () => {
  return (
    <div className="bg-gradient-to-br from-[#269DF3] to-[#1668B3] flex flex-col justify-center items-center font-newFont">
      <div className="w-11/12 h-[400px] flex flex-col justify-around items-center mt-10 md:h-1/2 md:flex-row md:w-4/5">
        <div className=" w-3/4 flex flex-col text-left justify-center md:justify-between">
          <img src={logo} className="my-2 md:w-1/4" />
          <p className="my-2 text-white text-lg font-thin md:w-1/3">
            La revolución del control de operaciones
          </p>
          <p className="my-2 text-white text-lg">Santiago, Chile</p>
        </div>
        <div className="w-3/4 flex flex-col text-left justify-center md:text-right">
          <p className="my-2 text-white text-xl font-bold">
            contacto@auditech.app
          </p>
          <div>
            <p className="mt-2 text-white text-2xl font-bold">+569 5147 2930</p>
            <p className="text-white">LUN - VIE 9:00 - 18:00</p>
          </div>
        </div>
      </div>
      <div className="w-full border md:mt-8"></div>
      <div className="w-2/3 text-left pb-10 md:w-4/5 md:text-center">
        <p className="text-white text-sm">
          © 2024 Auditech. Todos los derechos reservados
        </p>
      </div>
    </div>
  );
};
